import {
  ApolloProvider,
  useQuery as useQueryApollo,
  useMutation as useMutationApollo
} from '@apollo/client/react';
import {
  DocumentNode,
  OperationVariables,
  TypedDocumentNode
} from '@apollo/client/core';
import { IGraphQLService } from './services/GraphQLService/IGraphQLService';
import {
  ClientUseMutationResult,
  ClientUseQueryResult
} from './services/GraphQLService/types';
import { ReactNodeType, ReactType } from './react/types';
import GraphQLReactTools from './react/IGraphQLReactTools';
import NativeGraphQLReactTools from './react/NativeGraphQLReactTools';
import WebGraphQLReactTools from './react/WebGraphQLReactTools';
import { createNativeAppleAndroidGraphQLReactTools } from './react/NativeAppleAndroidGraphQLReactTools';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function useQuery<TData = Record<string, any>>(
  query: DocumentNode | TypedDocumentNode<TData>,
  options?: { variables: OperationVariables }
): ClientUseQueryResult<TData> {
  const result = useQueryApollo(query, options);
  return result;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function useMutation<TData = Record<string, any>>(
  mutation: DocumentNode | TypedDocumentNode<TData>,
  options?: { variables?: OperationVariables }
): ClientUseMutationResult<TData> {
  const result = useMutationApollo(mutation, options);
  return result as ClientUseMutationResult<TData>;
}

const createGraphQLProvider = (react: ReactType) => {
  const GraphQLProvider = ({
    children,
    client
  }: {
    children: ReactNodeType;
    client: IGraphQLService;
  }) => {
    return react.createElement(
      ApolloProvider,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      { client: (client as any)._apolloClient, children },
      children
    );
  };
  return GraphQLProvider;
};

export {
  createGraphQLProvider,
  useQuery,
  useMutation,
  WebGraphQLReactTools,
  NativeGraphQLReactTools,
  createNativeAppleAndroidGraphQLReactTools
};

export type { GraphQLReactTools };
