import { OperationVariables } from '@apollo/client';
import { DocumentNode, Kind, OperationTypeNode } from 'graphql';

/**
 * getNativeAppleAndroidApolloClientBody - Get the body for the MFE Apollo Client
 * @param query The query to be executed
 * @param options The options to be passed to the query
 * @returns The body for the MFE Apollo Client
 */
export function getNativeAppleAndroidApolloClientBody(
  query: DocumentNode,
  options?: { variables: OperationVariables }
) {
  const definition =
    query.definitions[0]?.kind === Kind.OPERATION_DEFINITION
      ? query.definitions[0]
      : undefined;
  const operationType =
    definition?.operation === OperationTypeNode.QUERY
      ? OperationTypeNode.SUBSCRIPTION
      : definition?.operation;
  let queryBody = query.loc?.source.body.trim();

  if (operationType === OperationTypeNode.SUBSCRIPTION) {
    queryBody = queryBody.replace(
      /^query /,
      `${OperationTypeNode.SUBSCRIPTION} `
    );
  }

  return {
    query: queryBody,
    variables: options?.variables || {},
    operationName: definition?.name?.value,
    operationType
  };
}
